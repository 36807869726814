<template>
  <ion-page>
    <Loading v-if="loading" />
    <div class="container">
      <header class="header">
        <img class="logo" src="@/img/chuteira-bola.svg" />
        <h1 class="h1-color">Registrar</h1>
      </header>

      <ion-card>
        <div>
          <p class="p-color">
            Nome Completo
            <ion-icon
              slot="end"
              v-if="nomeValidacao"
              color="danger"
              :icon="alertCircle"
              @click="setOpenPopover(true, nomeValidacao)"
            ></ion-icon>
          </p>
          <ion-input
            placeholder="Digite seu nome"
            :value="nome"
            @ionChange="nome = $event.target.value"
            @ionFocus="nomeValidacao = null"
          ></ion-input>
        </div>
      </ion-card>
      <ion-card>
        <div>
          <p class="p-color">
            Apelido
            <ion-icon
              slot="end"
              v-if="apelidoValidacao"
              color="danger"
              :icon="alertCircle"
              @click="setOpenPopover(true, apelidoValidacao)"
            ></ion-icon>
          </p>
          <ion-input
            placeholder="Digite seu apelido"
            :value="apelido"
            @ionChange="apelido = $event.target.value"
            @ionFocus="apelidoValidacao = null"
          ></ion-input>
        </div>
      </ion-card>
      <ion-card>
        <div>
          <p class="p-color">
            E-mail
            <ion-icon
              slot="end"
              v-if="emailValidacao"
              color="danger"
              :icon="alertCircle"
              @click="setOpenPopover(true, emailValidacao)"
            ></ion-icon>
          </p>
          <ion-input
            type="email"
            placeholder="Digite seu e-mail"
            :value="email"
            @ionChange="email = $event.target.value"
            @ionFocus="emailValidacao = null"
          ></ion-input>
        </div>
      </ion-card>
      <ion-card>
        <div>
          <p class="p-color">
            Senha
            <ion-icon
              slot="end"
              v-if="senhaValidacao"
              color="danger"
              :icon="alertCircle"
              @click="setOpenPopover(true, senhaValidacao)"
            ></ion-icon>
          </p>
          <div style="display: flex; align-items: center; padding-right: 9px">
            <ion-input
              placeholder="Digite sua senha..."
              :type="typeSenha"
              :value="senha"
              @ionChange="senha = $event.target.value"
              @ionFocus="senhaValidacao = null"
            ></ion-input>
            <ion-icon
              style="width: 10%"
              slot="end"
              v-if="typeSenha == 'password'"
              :icon="eye"
              @click="typeSenha = typeSenha == 'password' ? 'text' : 'password'"
            ></ion-icon>
            <ion-icon
              style="width: 10%"
              slot="end"
              v-else
              :icon="eyeOff"
              @click="typeSenha = typeSenha == 'password' ? 'text' : 'password'"
            ></ion-icon>
          </div>
        </div>
      </ion-card>
      <ion-card>
        <div>
          <p class="p-color">
            Confirme a senha
            <ion-icon
              slot="end"
              v-if="senhaConfValidacao"
              color="danger"
              :icon="alertCircle"
              @click="setOpenPopover(true, senhaConfValidacao)"
            ></ion-icon>
          </p>
          <div style="display: flex; align-items: center; padding-right: 9px">
            <ion-input
              placeholder="Confirme sua senha..."
              :type="typeConfSenha"
              :value="senhaConf"
              @ionChange="senhaConf = $event.target.value"
              @ionFocus="senhaConfValidacao = null"
            >
            </ion-input>
            <ion-icon
              style="width: 10%"
              slot="end"
              :icon="eye"
              v-if="typeConfSenha == 'password'"
              @click="
                typeConfSenha =
                  typeConfSenha == 'password' ? 'text' : 'password'
              "
            ></ion-icon>
            <ion-icon
              style="width: 10%"
              slot="end"
              :icon="eyeOff"
              v-else
              @click="
                typeConfSenha =
                  typeConfSenha == 'password' ? 'text' : 'password'
              "
            ></ion-icon>
          </div>
        </div>
      </ion-card>
      <ion-card>
        <div>
          <p class="p-color">
            Data de nascimento
            <ion-icon
              slot="end"
              v-if="dataNascimentoValidacao"
              color="danger"
              :icon="alertCircle"
              @click="setOpenPopover(true, dataNascimentoValidacao)"
            ></ion-icon>
          </p>
          <ion-datetime
            cancelText="CANCELAR"
            doneText="SALVAR"
            display-format="DD/MM/YYYY"
            :value="dataNascimento"
            @ionChange="dataNascimento = $event.target.value"
            @ionFocus="dataNascimentoValidacao = null"
          ></ion-datetime>
        </div>
      </ion-card>

      <footer class="footer">
        <ion-button @click="goToLogin()">Login</ion-button>
        <ion-button @click="register()">Cadastrar</ion-button>
      </footer>
    </div>

    <ion-popover
      :is-open="isOpenPopover"
      :event="event"
      :translucent="true"
      @onDidDismiss="setOpenPopover(false)"
    >
      <Popover
        title="Campo inválido"
        type="validation"
        :message="mensagem"
      ></Popover>
    </ion-popover>

    <ion-toast
      :is-open="isOpenToast"
      :color="
        mensagemResposta == 'Jogador cadastrado com sucesso'
          ? 'success'
          : 'danger'
      "
      :message="mensagemResposta"
      :duration="2000"
      @onDidDismiss="setOpenToast(false)"
    >
    </ion-toast>
  </ion-page>
</template>

<script>
import { IonPage, IonPopover, IonToast } from "@ionic/vue";
import { alertCircle, eye, eyeOff } from "ionicons/icons";
import { useRouter } from "vue-router";
import Popover from "@/components/Popover.vue";
import Loading from "@/components/Layout/Loading.vue";

export default {
  name: "Register",
  components: { IonPage, IonPopover, Popover, IonToast, Loading },
  data() {
    return {
      router: useRouter(),
      isOpenPopover: false,
      isOpenToast: false,
      loading: false,
      erroLogin: true,
      nome: "",
      apelido: "",
      email: "",
      senha: "",
      senhaConf: "",
      dataNascimento: "",
      typeSenha: "password",
      typeConfSenha: "password",
      mensagemResposta: "",
      /* Validações */
      titulo: "",
      mensagem: "",
      nomeValidacao: null,
      apelidoValidacao: null,
      emailValidacao: null,
      senhaValidacao: null,
      senhaConfValidacao: null,
      dataNascimentoValidacao: null,
    };
  },
  computed: {
    alertCircle() {
      return alertCircle;
    },
    eye() {
      return eye;
    },
    eyeOff() {
      return eyeOff;
    },
    validation() {
      return false;
    },
  },
  methods: {
    goToLogin() {
      this.router.replace("/Login");
    },
    validate() {
      if (!this.nome) this.nomeValidacao = "Por favor digite seu nome";
      else this.nomeValidacao = null;

      if (!this.apelido) this.apelidoValidacao = "Por favor digite um apelido";
      else this.apelidoValidacao = null;

      const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/i;
      if (!regex.test(this.email))
        this.emailValidacao = "Por favor digite um e-mail válido";
      else this.emailValidacao = null;

      if (!this.senha) this.senhaValidacao = "Por favor digite uma senha";
      else this.senhaValidacao = null;

      if (this.senha.length <= 6)
        this.senhaValidacao = "Por favor digite uma senha mais difícil";
      else this.senhaValidacao = null;

      if (!this.senhaConf)
        this.senhaConfValidacao = "Por favor confirme sua senha";
      else this.senhaConfValidacao = null;

      if (this.senha != this.senhaConf)
        this.senhaConfValidacao = "As senhas não conferem";
      else this.senhaConfValidacao = null;

      if (!this.dataNascimento)
        this.dataNascimentoValidacao =
          "Por favor selecione sua data de nascimento";
      else this.dataNascimentoValidacao = null;

      return (
        !this.nomeValidacao &&
        !this.apelidoValidacao &&
        !this.emailValidacao &&
        !this.senhaValidacao &&
        !this.senhaConfValidacao &&
        !this.dataNascimentoValidacao
      );
    },
    setOpenPopover(pState, pMensagem) {
      this.mensagem = pMensagem;
      this.isOpenPopover = pState;
    },
    setOpenToast(pState) {
      this.isOpenToast = pState;
    },
    async register() {
      if (this.validate()) {
        this.loading = true;
        const resposta = await this.$store.dispatch("login/register", {
          nome: this.nome,
          apelido: this.apelido,
          email: this.email.toLowerCase(),
          senha: this.senha,
          senhaConf: this.senhaConf,
          dataNascimento: this.dataNascimento.split("T")[0],
        });

        this.mensagemResposta = resposta;
        this.setOpenToast(true);

        if (resposta == "Jogador cadastrado com sucesso") {
          // this.router.replace("/Login");
        } else {
          this.erroLogin = true;
        }
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
h1,
p {
  margin: 0;
  height: 36px;
}

.header {
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 10%;
  margin-bottom: 20px;
}

.response-message {
  width: 80%;
  height: auto;
  margin: auto;
  line-height: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.logo {
  height: 70%;
}

.container {
  height: auto;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  overflow: auto !important;
  background-color: white;
}

body.dark .container {
  background-color: #121212;
}

ion-icon {
  font-size: 25px;
}

ion-select,
ion-select-option {
  --color: gray !important;
  --padding-start: 8px;
}

ion-datetime {
  color: gray !important;
  --padding-start: 8px;
}

ion-label {
  display: flex;
  width: 100%;
  font-weight: bold;
  --color: gray !important;
  padding-bottom: 13px;
  padding-top: 0;
  margin-top: 0;
}

ion-card p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 5px 10px 5px 10px;
  border-bottom: 1px solid gray;
  border-bottom: 1px solid #d9d9d9;
  background-color: #f3f3f3;
}

.footer {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
</style>
